import {
  Box,
  Flex,
  Image,
  Link,
  Skeleton,
  SkeletonText,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { IOffering } from '@r-client/data/graphql-types';
import { useAnalytics } from '@r-client/shared/data/analytics';
import { InfoSquareIcon, PinIcon } from '@r-client/shared/ui/chakra-ui-icons';

export interface ISpotlightDealProps {
  offering?: Pick<
    IOffering,
    'companyName' | 'cardImageUrl' | 'description' | 'slug' | 'id'
  >;
  isLoading: boolean;
}

export const SpotlightDeal = ({ offering, isLoading }: ISpotlightDealProps) => {
  const analytics = useAnalytics();
  if (!offering && !isLoading) {
    return null;
  }

  const trackHeaderSpotlightDealClick = () => {
    if (offering) {
      analytics.track({
        name: 'header_spotlight_clicked',
        params: {
          spotlight_offering_id: offering.id,
          spotlight_offering_slug: offering.slug,
          spotlight_offering_company: offering.companyName,
          platform: 'web',
        },
      });
    }
  };

  return (
    <Box py="12">
      <Flex mb="5" mt="1" alignItems="center">
        <Skeleton
          borderRadius="lg"
          animation="none"
          isLoaded={!isLoading}
          startColor="grey.50"
          endColor="grey.50"
          fitContent
          w="100%"
        >
          <PinIcon
            width="12px"
            height="14px"
            fill="grey.400"
            color="grey.400"
          />
          <Text
            textStyle="md"
            fontWeight="semibold"
            lineHeight="16px"
            mr="2"
            ml="2"
            display="inline-flex"
          >
            Spotlight deal
          </Text>
          <Tooltip
            backgroundColor="grey.900"
            hasArrow
            label="This link showcases content from our partners, distinct from our regular content."
            placement="top"
            variant="republic"
          >
            <InfoSquareIcon />
          </Tooltip>
        </Skeleton>
      </Flex>
      <Link
        href={isLoading ? undefined : `/${offering?.slug}`}
        role="group"
        _hover={{ textDecoration: 'none' }}
        onClick={trackHeaderSpotlightDealClick}
        variant="republic-default"
        size="republic-default"
      >
        <Box mb="3">
          <Skeleton
            borderRadius="lg"
            animation="none"
            isLoaded={!isLoading}
            startColor="grey.50"
            endColor="grey.50"
            fitContent
            w="100%"
          >
            <Image
              src={offering?.cardImageUrl || ''}
              width="100%"
              height="134px"
              borderRadius="lg"
              objectFit="cover"
            />
          </Skeleton>
        </Box>

        <Box>
          <SkeletonText
            mb="1"
            borderRadius="base"
            noOfLines={1}
            spacing="3"
            skeletonHeight="5"
            animation="none"
            startColor="grey.50"
            endColor="grey.50"
            isLoaded={!isLoading}
          >
            <Text mb="1">{offering?.companyName}</Text>
          </SkeletonText>
          <SkeletonText
            borderRadius="base"
            noOfLines={1}
            spacing="3"
            skeletonHeight="5"
            animation="none"
            startColor="grey.50"
            endColor="grey.50"
            isLoaded={!isLoading}
          >
            <Text
              textStyle="sm"
              color="grey.500"
              _groupHover={{ color: 'grey.900' }}
            >
              {offering?.description}
            </Text>
          </SkeletonText>
        </Box>
      </Link>
    </Box>
  );
};

import { Box, Divider, Flex, Link, Skeleton, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import {
  IssuerProfileDropdown,
  UserDropdown,
} from '@r-client/republic/feature/layout';
import { NotificationsDropdownMenuButton } from '@r-client/republic/feature/notifications';
import { useAnalytics } from '@r-client/shared/data/analytics';
import { MaybeValue, Viewer } from '@r-client/shared/feature/auth';
import { GlobeIcon, PortfolioIcon } from '@r-client/shared/ui/chakra-ui-icons';
import { Avatar } from '@r-client/shared/ui/core';
import { Tooltip } from '@r-client/shared/ui/core';

import { HEADER_THEMES } from '../../types';
import { getSignInPath } from '../../utils/get-signin-path';
import { getTextColor } from '../../utils/get-text-color';
import { MessagesMenu } from '../messages-menu/messages-menu';
import { RegionSwitcherDesktop } from '../region-switcher/region-switcher-desktop';
import { IRegionSwitcherSettings } from '../region-switcher/types';

interface IUserMenuProps {
  theme: HEADER_THEMES;
  regionSwitcherSettings?: IRegionSwitcherSettings;
  user: {
    viewer: MaybeValue<Viewer>;
    isLoading?: boolean;
  };
  onNotificationsClick: () => void;
}

export const UserMenu = ({
  theme,
  user: { viewer, isLoading },
  onNotificationsClick,
  regionSwitcherSettings,
}: IUserMenuProps) => {
  const path = useRouter().pathname;
  const analytics = useAnalytics();

  const editableOfferings = viewer?.info?.editableOfferings.filter((editable) =>
    viewer.info?.issuerProfiles.some(
      (profile) =>
        !profile.offerings.some((offering) => offering.id === editable.id)
    )
  );

  const trackPortfolioIconClick = () => {
    analytics.track({
      name: 'header_icon_clicked',
      params: { label: 'my_portfolio', platform: 'web' },
    });
  };

  return (
    <Flex>
      {isLoading && !viewer && (
        <Flex alignItems="center" gap="4">
          <Skeleton
            borderRadius="xl"
            startColor="grey.50"
            endColor="rgba(242,242,242, 0.5)"
            height={{ xs: '20px' }}
            width={{ xs: '60px', md: '60px' }}
          />
          <Skeleton
            borderRadius="base"
            startColor="grey.50"
            endColor="rgba(242,242,242, 0.5)"
            height={{ xs: '35px', md: '35px' }}
            width={{ xs: '35px', md: '35px' }}
          />
        </Flex>
      )}
      {!isLoading && viewer?.info && (
        <Flex mr="6" alignItems="center" gap="5">
          {regionSwitcherSettings ? (
            <RegionSwitcherDesktop
              regionalSwitcherSettings={regionSwitcherSettings}
              variant={theme}
            />
          ) : (
            <GlobeButton
              tooltipText="You are viewing the global site"
              theme={theme}
            />
          )}
          <Box py="1.5" height="100%" mr="-2px" ml="-6px">
            <Divider
              orientation="vertical"
              borderColor="grey.100"
              opacity="1"
            />
          </Box>
          <MessagesMenu
            unreadMessages={viewer?.info?.unreadMessagesCount}
            theme={theme}
          />
          <NotificationsDropdownMenuButton
            color={getTextColor(theme)}
            unreadNotificationsCount={
              viewer?.info?.unreadNotificationsCount ?? 0
            }
            onNotificationsClick={onNotificationsClick}
          />

          <Tooltip content="My portfolio" delayHide={0} placement="bottom">
            <Link
              href="/portfolio"
              onClick={trackPortfolioIconClick}
              variant="republic-default"
              size="republic-default"
            >
              <PortfolioIcon boxSize="6" color={getTextColor(theme)} />
            </Link>
          </Tooltip>
        </Flex>
      )}
      {!isLoading && !viewer?.info && (
        <Flex gap="6" flexDirection="row" alignItems="center" ml="4">
          {regionSwitcherSettings ? (
            <RegionSwitcherDesktop
              regionalSwitcherSettings={regionSwitcherSettings}
              variant={theme}
            />
          ) : (
            <GlobeButton
              tooltipText="You are viewing the global site"
              theme={theme}
            />
          )}
          <Link
            href={getSignInPath(path)}
            _hover={{
              textDecoration: 'none',
              color: 'blue.500',
            }}
            variant="republic-default"
            size="republic-default"
          >
            <Text color={getTextColor(theme)} letterSpacing="less-tight">
              Log in
            </Text>
          </Link>
          <Link
            href="/register"
            _hover={{
              textDecoration: 'none',
              color: 'blue.500',
            }}
            variant="republic-default"
            size="republic-default"
          >
            <Text color={getTextColor(theme)} letterSpacing="less-tight">
              Sign up
            </Text>
          </Link>
        </Flex>
      )}
      {!!viewer?.info?.issuerProfiles &&
        !!viewer.info.issuerProfiles.length && (
          <IssuerProfileDropdown
            url={viewer.info.issuerProfiles[0].offerings[0]?.logoUrl ?? ''}
            companyName={viewer.info.issuerProfiles[0].companyName || ''}
            onboardingCompleteness={
              viewer.info.issuerProfiles[0].onboardingCompleteness
            }
            offerings={viewer.info.issuerProfiles[0].offerings}
          />
        )}
      {viewer?.info && (
        <UserDropdown
          userSlug={viewer.info.slug}
          editableOfferings={editableOfferings}
          isFounder={!!viewer?.info.issuerProfiles.length}
          triggerElement={
            <Link
              href={`/@${viewer?.info.slug}`}
              variant={undefined}
              size="republic-default"
            >
              <div data-testid="header-avatar">
                <Avatar
                  outline={false}
                  url={viewer.info?.avatarUrl ?? undefined}
                  size="xSmall"
                  badge={viewer.info.isAdmin ? 'admin' : undefined}
                />
              </div>
            </Link>
          }
        />
      )}
    </Flex>
  );
};

interface IGlobeIconProps {
  text?: string;
  tooltipText: string;
  theme: HEADER_THEMES;
}

function GlobeButton({ text, tooltipText, theme }: IGlobeIconProps) {
  return (
    <Tooltip content={tooltipText} delayHide={0} placement="bottom">
      <Flex
        _hover={theme !== 'dark' ? { bg: 'grey.50', color: 'grey.900' } : {}}
        borderRadius="8px"
        padding="7px"
        color={getTextColor(theme)}
        cursor="default"
        alignItems="center"
      >
        <GlobeIcon boxSize="5" />
        {text && (
          <Text
            as="span"
            verticalAlign="middle"
            ml="1"
            fontSize="sm"
            color={getTextColor(theme, '600')}
          >
            {text}
          </Text>
        )}
      </Flex>
    </Tooltip>
  );
}
